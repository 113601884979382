import messages_cs from './supported-languages/cs.json';
import messages_da from './supported-languages/da.json';
import messages_de from './supported-languages/de.json';
import messages_en from './supported-languages/en.json';
import messages_es from './supported-languages/es.json';
import messages_et from './supported-languages/et.json';
import messages_fi from './supported-languages/fi.json';
import messages_fr from './supported-languages/fr.json';
import messages_hu from './supported-languages/hu.json';
import messages_it from './supported-languages/it.json';
import messages_lt from './supported-languages/lt.json';
import messages_lv from './supported-languages/lv.json';
import messages_nb from './supported-languages/nb.json';
import messages_nl from './supported-languages/nl.json';
import messages_pl from './supported-languages/pl.json';
import messages_pt from './supported-languages/pt.json';
import messages_ro from './supported-languages/ro.json';
import messages_ru from './supported-languages/ru.json';
import messages_sk from './supported-languages/sk.json';
import messages_sl from './supported-languages/sl.json';
import messages_sv from './supported-languages/sv.json';
import messages_tr from './supported-languages/tr.json';

import legal_agreements_de from './legal-agreements/de.json';
import legal_agreements_en from './legal-agreements/en.json';
import legal_agreements_es from './legal-agreements/es.json';
import legal_agreements_nl from './legal-agreements/nl.json';
import legal_agreements_sv from './legal-agreements/sv.json';

// TODO: Investigate whether d3-time-format can be replaced,
// (i.e. with moment), as it seems to lack required locales.
import d3Time_cs from 'd3-time-format/locale/cs-CZ.json';
import d3Time_da from 'd3-time-format/locale/da-DK.json';
import d3Time_de from 'd3-time-format/locale/de-DE.json';
import d3Time_en from 'd3-time-format/locale/en-US.json';
import d3Time_es from 'd3-time-format/locale/es-ES.json';
import d3Time_fi from 'd3-time-format/locale/fi-FI.json';
import d3Time_fr from 'd3-time-format/locale/fr-FR.json';
import d3Time_hu from 'd3-time-format/locale/hu-HU.json';
import d3Time_it from 'd3-time-format/locale/it-IT.json';
import d3Time_nb from 'd3-time-format/locale/nb-NO.json';
import d3Time_nl from 'd3-time-format/locale/nl-NL.json';
import d3Time_pl from 'd3-time-format/locale/pl-PL.json';
import d3Time_pt from 'd3-time-format/locale/pt-BR.json';
import d3Time_ru from 'd3-time-format/locale/ru-RU.json';
import d3Time_sv from 'd3-time-format/locale/sv-SE.json';
import d3Time_tr from 'd3-time-format/locale/tr-TR.json';

import { generateD3TimeLocales } from './utils';

const d3Time_et = generateD3TimeLocales({ messages: messages_et });
const d3Time_ro = generateD3TimeLocales({ messages: messages_ro });
const d3Time_sk = generateD3TimeLocales({ messages: messages_sk });
const d3Time_sl = generateD3TimeLocales({ messages: messages_sl });
const d3Time_lt = generateD3TimeLocales({ messages: messages_lt });
const d3Time_lv = generateD3TimeLocales({ messages: messages_lv });

export const cultures = {
  cs: {
    language: 'cs',
    dialects: ['cs', 'cs-CZ'],
    messages: { ...messages_cs, ...legal_agreements_en }, //since we don't have legal agreements translated, fallback to english
    d3TimeLocale: d3Time_cs,
    momentLocale: 'cs',
    languageTag: 'cs-CZ',
  },
  da: {
    language: 'da',
    dialects: ['da'],
    messages: { ...messages_da, ...legal_agreements_en },
    d3TimeLocale: d3Time_da,
    momentLocale: 'da',
    languageTag: 'da-DK',
  },
  de: {
    language: 'de',
    dialects: ['de', 'de-AT', 'de-DE', 'de-LI', 'de-LI', 'de-CH'],
    messages: { ...messages_de, ...legal_agreements_de },
    d3TimeLocale: d3Time_de,
    momentLocale: 'de-at',
    languageTag: 'de-DE',
  },
  en: {
    language: 'en',
    dialects: [
      'en',
      'en-AU',
      'en-BZ',
      'en-CA',
      'en-CB',
      'en-IE',
      'en-JM',
      'en-NZ',
      'en-PH',
      'en-ZA',
      'en-TT',
      'en-GB',
      'en-US',
      'en-ZW',
    ],
    messages: { ...messages_en, ...legal_agreements_en },
    d3TimeLocale: d3Time_en,
    momentLocale: 'en',
    languageTag: 'en-US',
  },
  es: {
    language: 'es',
    dialects: ['es', 'es-ES', 'es-MX'],
    messages: { ...messages_es, ...legal_agreements_es },
    d3TimeLocale: d3Time_es,
    momentLocale: 'es',
    languageTag: 'es-ES',
  },
  et: {
    language: 'et',
    dialects: ['et', 'et-EE'],
    messages: { ...messages_et, ...legal_agreements_en },
    d3TimeLocale: d3Time_et,
    momentLocale: 'et',
    languageTag: 'et-EE',
  },
  fi: {
    language: 'fi',
    dialects: ['fi', 'fi-FI'],
    messages: { ...messages_fi, ...legal_agreements_en },
    d3TimeLocale: d3Time_fi,
    momentLocale: 'fi',
    languageTag: 'fi-FI',
  },
  fr: {
    language: 'fr',
    dialects: ['fr', 'fr-BE', 'fr-CA', 'fr-FR', 'fr-LU', 'fr-MC', 'fr-CH'],
    messages: { ...messages_fr, ...legal_agreements_en },
    d3TimeLocale: d3Time_fr,
    momentLocale: 'fr',
    languageTag: 'fr-FR',
  },
  hu: {
    language: 'hu',
    dialects: ['hu', 'hu-HU'],
    messages: { ...messages_hu, ...legal_agreements_en },
    d3TimeLocale: d3Time_hu,
    momentLocale: 'hu',
    languageTag: 'hu-HU',
  },
  it: {
    language: 'it',
    dialects: ['it', 'it-IT'],
    messages: { ...messages_it, ...legal_agreements_en },
    d3TimeLocale: d3Time_it,
    momentLocale: 'it',
    languageTag: 'it-IT',
  },
  nl: {
    language: 'nl',
    dialects: ['nl', 'nl-BE', 'nl-NL'],
    messages: { ...messages_nl, ...legal_agreements_nl },
    d3TimeLocale: d3Time_nl,
    momentLocale: 'nl',
    languageTag: 'nl-NL',
  },
  nb: {
    language: 'nb',
    dialects: ['nb', 'no', 'nn', 'nb-NO', 'nn-NO'],
    messages: { ...messages_nb, ...legal_agreements_en },
    d3TimeLocale: d3Time_nb,
    momentLocale: 'nb',
    languageTag: 'nb-NO',
  },
  pl: {
    language: 'pl',
    dialects: ['pl', 'pl-PL'],
    messages: { ...messages_pl, ...legal_agreements_en },
    d3TimeLocale: d3Time_pl,
    momentLocale: 'pl',
    languageTag: 'pl-PL',
  },
  ro: {
    language: 'ro',
    dialects: ['ro'],
    messages: { ...messages_ro, ...legal_agreements_en },
    d3TimeLocale: d3Time_ro,
    momentLocale: 'ro',
    languageTag: 'ro-RO',
  },
  ru: {
    language: 'ru',
    dialects: ['ru', 'ru-RU'],
    messages: { ...messages_ru, ...legal_agreements_en },
    d3TimeLocale: d3Time_ru,
    momentLocale: 'ru',
    languageTag: 'ru-RU',
  },
  sk: {
    language: 'sk',
    dialects: ['sk', 'sk-SK'],
    messages: { ...messages_sk, ...legal_agreements_en },
    d3TimeLocale: d3Time_sk,
    momentLocale: 'sk',
    languageTag: 'sk-SK',
  },
  sl: {
    language: 'sl',
    dialects: ['sl', 'sl-SI'],
    messages: { ...messages_sl, ...legal_agreements_en },
    d3TimeLocale: d3Time_sl,
    momentLocale: 'sl',
    languageTag: 'sl-SI',
  },
  sv: {
    language: 'sv',
    dialects: ['sv', 'sv-SE', 'sv-FI'],
    messages: { ...messages_sv, ...legal_agreements_sv },
    d3TimeLocale: d3Time_sv,
    momentLocale: 'sv',
    languageTag: 'sv-SE',
  },
  lv: {
    language: 'lv',
    dialects: ['lv', 'lv-LV'],
    messages: { ...messages_lv, ...legal_agreements_en },
    d3TimeLocale: d3Time_lv,
    momentLocale: 'lv',
    languageTag: 'lv-LV',
  },
  lt: {
    language: 'lt',
    dialects: ['lt', 'lt-LT'],
    messages: { ...messages_lt, ...legal_agreements_en },
    d3TimeLocale: d3Time_lt,
    momentLocale: 'lt',
    languageTag: 'lt-LT',
  },
  pt: {
    language: 'pt',
    dialects: ['pt', 'pt-BR'],
    messages: { ...messages_pt, ...legal_agreements_en },
    d3TimeLocale: d3Time_pt,
    momentLocale: 'pt',
    languageTag: 'pt-BR',
  },
  tr: {
    language: 'tr',
    dialects: ['tr', 'tr-TR'],
    messages: { ...messages_tr, ...legal_agreements_en },
    d3TimeLocale: d3Time_tr,
    momentLocale: 'tr',
    languageTag: 'tr-TR',
  },
};
