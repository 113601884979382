import React, { Suspense, useCallback, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { getLastUsedSystemId } from '@/utils/localStorage/lastUsedSystem';

import Loader from '@jpi-cloud-web-pro/jpi-cloud-shared/components/common/Loader';
import ThemeWrapper from '../ThemeWrapper';

import { getDeviceFeatures, getSPSubscriptionStatus } from '../FeaturesFlags/actions';

import {
  getAllBrands,
  getAllLanguages,
  getCloudStatus,
  getCountries,
  getLastUsedLanguage,
  getLatestAgreementsVersions,
  getProAccount,
  getSupportedLanguages,
  getUserInfo,
  getUserSystems,
  initApp,
  setUserDataState,
} from './actions';

import { CLOUD_STATUS_CHECK_INTERVAL } from '@/config/common';

import events, { eventEmitter } from '@/events';

import { preventGoogleFontsLoading } from './utils';

import logger from '@/utils/logger';
import { UserDataState } from './types';

export function AppInitializer({
  userLoggedIn,
  userDataState,
  userInfo,
  servicePartner,
  initApp,
  getCloudStatus,
  getUserInfo,
  getUserSystems,
  getSPSubscriptionStatus,
  getDeviceFeatures,
  getCountries,
  getLatestAgreementsVersions,
  getAllBrands,
  setUserDataState,
  getLastUsedLanguage,
  getProAccount,
  getAllLanguages,
  getSupportedLanguages,
  children,
}) {
  const refreshIntervalCycle = useRef(null);

  const checkCloudStatus = async () => {
    try {
      await getCloudStatus();
    } catch (error) {
      eventEmitter.emit(events.appInsights.logError, error);
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    }
  };

  const initialize = async () => {
    try {
      await initApp();

      await Promise.all([getCountries(), getLatestAgreementsVersions(), getCloudStatus()]);
    } catch (error) {
      eventEmitter.emit(events.appInsights.logError, error);
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    }

    refreshIntervalCycle.current = setInterval(checkCloudStatus, CLOUD_STATUS_CHECK_INTERVAL);
  };

  const handleTokenExpiration = () => {
    NotificationManager.warning(
      <FormattedMessage id="warning.auth.token" defaultMessage="Your session has expired, please log in again." />,
    );
  };

  useEffect(() => {
    preventGoogleFontsLoading();
    initialize();

    eventEmitter.on(events.inApp.auth.TOKEN_EXPIRED, handleTokenExpiration);

    return () => {
      eventEmitter.off(events.inApp.auth.TOKEN_EXPIRED, handleTokenExpiration);
      clearInterval(refreshIntervalCycle.current);
    };
  }, []);

  const loadData = async () => {
    await getAllLanguages();
    await getProAccount(userInfo.spId);

    const system = await getUserSystems(getLastUsedSystemId());

    const [device] = system?.devices || [];
    if (device) await getDeviceFeatures(device.id);

    await getSPSubscriptionStatus();
  };

  const handleUserDataStates = useCallback(async () => {
    try {
      if (userDataState === UserDataState.REQUESTED && !userInfo && userLoggedIn) {
        await getUserInfo();
        return;
      }

      if (userDataState === UserDataState.REQUESTED && userInfo) {
        if (!servicePartner && userInfo.spId) {
          await loadData();
        }

        const getLanguages = servicePartner ? getSupportedLanguages : getAllLanguages;

        await getLanguages();
        await getAllBrands();

        setUserDataState(UserDataState.LOADING);
        return;
      }

      if (userDataState === UserDataState.LOADING && userInfo) {
        setUserDataState(UserDataState.LOADED);
        return;
      }
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
      logger.toAppInsights(error);
    }
  }, [userInfo, userDataState]);

  useEffect(() => {
    handleUserDataStates();
  }, [handleUserDataStates]);

  const handleUserAuth = () => {
    if (userLoggedIn) return setUserDataState(UserDataState.REQUESTED);

    setUserDataState(UserDataState.LOADED);
  };

  useEffect(() => {
    handleUserAuth();
  }, [userLoggedIn]);

  const appLoading = userDataState !== UserDataState.LOADED;

  return (
    <Suspense fallback="">
      <ThemeWrapper>{appLoading ? <Loader dark isPage /> : children}</ThemeWrapper>
    </Suspense>
  );
}

AppInitializer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  initApp: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  getSPSubscriptionStatus: PropTypes.func.isRequired,
  getDeviceFeatures: PropTypes.func.isRequired,
  getProAccount: PropTypes.func.isRequired,
  getCountries: PropTypes.func,
  getLatestAgreementsVersions: PropTypes.func.isRequired,
  getCloudStatus: PropTypes.func.isRequired,
  getAllBrands: PropTypes.func.isRequired,
  setUserDataState: PropTypes.func.isRequired,
  getLastUsedLanguage: PropTypes.func.isRequired,
  getAllLanguages: PropTypes.func.isRequired,
  getSupportedLanguages: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.bool,
  userInfo: PropTypes.object,
  userDataState: PropTypes.string,
  servicePartner: PropTypes.object,
};

const actionProps = {
  initApp,
  getUserInfo,
  getUserSystems,
  getSPSubscriptionStatus,
  getDeviceFeatures,
  getCountries,
  getLatestAgreementsVersions,
  getCloudStatus,
  getAllBrands,
  setUserDataState,
  getLastUsedLanguage,
  getProAccount,
  getAllLanguages,
  getSupportedLanguages,
};

export default connect(({ app }) => ({ ...app }), actionProps)(AppInitializer);
